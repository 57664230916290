/* Begin Overview */

.App {
  background-color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  overflow-y: hidden;
}

@font-face {
  font-family: "Caslon";
  src: local("Caslon"), url(./fonts/Caslon.ttf) format("truetype");
}

* {
  margin: 0;
}

.main {
  display: flex;
  flex-flow: row nowrap;
}

/* End Overview */

/* Begin Side Nav */

.left-column {
  width: 20vw;
  height: 100vh;
  border-right: 1px solid black;
  font-family: Helvetica, sans-serif;
  padding-right: 28px;
}

h1 {
  padding-left: 33px;
  padding-top: 47px;
}

h3 {
  padding-left: 33px;
  padding-top: 16px;
  font-family: Caslon, serif;
  font-weight: normal;
}

.nav {
  display: flex;
  justify-content: flex-end;
  padding-top: 20vh;
}

.nav-item {
  font-weight: bold;
}

a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

a.selected {
  text-decoration: underline;
}

.sub-nav {
  max-height: 0;
  transition: max-height 600ms ease-in-out;
  overflow-y: hidden;
}

.sub-nav-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.chevron {
  padding: 5px;
  width: 10px;
}

.sub-nav-container:hover .sub-nav {
  max-height: 200px;
}

.sub-nav-container:not(:hover) .chevron {
  transform: rotate(0);
  transition: transform 300ms ease-in-out;
}

.sub-nav-container:hover .chevron {
  transform: rotate(180deg);
  transition: transform 300ms ease-in-out;
}

.sub-nav-item {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
  text-align: right;
}

li {
  padding-top: 25px;
}

@keyframes open-sub-nav {
  from {
    color: white;
    max-height: 0px;
  }
  to {
    color: black;
    max-height: 100px;
  }
}

@keyframes animate-underline {
  from {
    text-decoration: none;
  }
  to {
    text-decoration: underline;
  }
}

.footer {
  position: absolute;
  padding: 33px;
  bottom: 0;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  margin-bottom: auto;
}

/* End Side Nav */

/* Begin Content Showcase */
.right-column {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}

iframe {
  border: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  padding-bottom: 2px;
  margin: 0;
}

h2 {
  font-weight: normal;
}

.content-info {
  border-top: 1px solid black;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  height: 100%;
  animation: pop-up-content-info 300ms 1 ease-in-out;
  transition: max-height 750ms ease-in-out;
  overflow-y: clip;
  display: flex;
  flex-flow: column nowrap;
}

.content-info:hover {
  background-color: #ebebeb;
}

.content-info.open {
  max-height: 25vh;
}

.content-info.closed {
  max-height: 45px;
}

@keyframes pop-up-content-info {
  from {
    max-height: 0;
  }
  to {
    max-height: 45px;
  }
}

.content-info-header {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 20px;
}

.content-info-chevron {
  padding: 5px;
  width: 32px;
  transition: transform 750ms ease-in-out;
}

.content-info.open .content-info-chevron {
  transform: rotate(-180deg);
}

.content-info.closed .content-info-chevron {
  transform: rotate(0deg);
}

.content-info-body {
  margin-top: 25px;
  margin-bottom: 25px;
  height: 80%;
  font-family: Caslon, serif;
  overflow-y: scroll;
}

.tag-list {
  display: flex;
  flex-flow: row wrap;
}

.tag {
  border-radius: 50px;
  border: 1px solid black;
  padding: 5px 20px 5px 20px;
  margin-right: 12px;
  background-color: white;
  color: black;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  font-weight: bold;
}

/* End Content Showcase */

/* Begin Bio Showcase */

.bio-showcase {
  overflow: hidden;
  max-height: 100vh;
}

.bio-img {
  width: 140%;
  height: 100%;
  margin-top: -75px;
}

.bio-caption {
  position: absolute;
  color: white;
  font-family: Caslon, serif;
  font-size: 18px;
  padding: 50px;
  width: 380px;
  white-space: pre-wrap;
  text-align: justify;
}

/* End Bio Showcase */

/* Begin Contact Page */

.contact-form {
  overflow: hidden;
  max-height: 100vh;
}

.contact-cta {
  color: black;
  font-family: Caslon, serif;
  font-size: 24px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
  grid-row: 1;
  grid-column: 1 / span 2;
  white-space: pre-wrap;
  text-align: justify;
  z-index: 100;
}

.form-group {
  position: absolute;
  z-index: 1;
  width: 70vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(7, 20px);
  row-gap: 25px;
  column-gap: 40px;
  align-items: center;
  align-content: center;
  height: 100vh;
  padding-left: 50px;
  padding-right: 50px;
}

.form-input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 1px;
  border-bottom-color: black;
  background-color: transparent;
  padding: 5px;
  padding-top: 7px;
  font-family: Caslon, serif;
  font-size: 18px;
  grid-column: 1;
}

.form-input::placeholder {
  color: black;
  font-family: Helvetica, sans-serif;
  font-size: 12px;
}
.form-input:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.form-input:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

label {
  font-family: Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.form-input:focus::placeholder {
  color: transparent;
}

textarea.form-input {
  height: 100%;
  grid-row: 4 / span 3;
  align-self: flex-start;
}

textarea:focus,
input:focus {
  outline: none;
}

.button-submit {
  font-family: Helvetica, sans-serif;
  font-size: 18px;
  text-align: center;
  border-width: 0;
  border-radius: 20px;
  padding: 10px;
  grid-row: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background-color: black;
  color: white;
}

.button-submit:hover {
  background-color: grey;
}

.contact-img {
  transform: scaleX(-1);
  height: 100%;
  width: 100%;
}

/* End Contact Page */
